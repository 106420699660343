#page-blog{
  article{
    margin-bottom: 50px;
  }
  h1{
    font-size: 1.6rem;
    text-align:center;
    @include max($break-mobile){
      text-align:left;
    }
    a{
      color:black;
    }
  }
  .small{
    display:block;
    text-align:center;
    margin-bottom: .5em;
    @include max($break-mobile){
      text-align:left;
    }
  }
  img{
    display:table;
    margin:0 auto 15px;
  }
}

#page-blog-single{
  .small-date{
    display:block;
    margin:3em 0 .7em;
  }
  h1{
    line-height: 1;
  }
  .eye-c{
    img{
      display:table;
      margin:0 auto;
    }
  }
  .bloc-content{
    margin-top: 30px;
    *{
      @include text-just();
    }
  }
  aside{
    display:block;
    margin-bottom: 30px;
    padding-left: 15px;
    @include max($break-mobile){
      padding-left: 0px;
    }
    &:last-child(){
      margin-bottom: 0;
    }
    h2{
      font-size:1.6rem;
      font-weight:600;
    }
    ul,ol{
      list-style:none;
      padding-left: 0;
      li{
        margin-bottom: .5em;
        >a{
          color:black;
        }
      }
    }
    .thumnails{
      width:30%;
      margin-right: 10px;
    }
    .d-flex{
      a{
        color:black;
      }
      .small{
        display:block;
      }
    }
  }
}