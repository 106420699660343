@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "site-variables";

.site-container{
  width: 970px;
  margin:{
    left: auto;
    right: auto;
  }
}

#global-header{

}

.fancybox-bg{
  background-color: #fff !important;
}

.grid-item{
  margin-bottom: 15px;
}

#breadcrumbs{
  margin-bottom: 15px;
  a{
    color:black;
  }
}
.wp-pagenavi{
  margin-top: 15px;
}