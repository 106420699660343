#page-index{
  h2{
    @include max($break-mobile){
      max-width: 60%;
      display:table;
      margin:0 auto 50px;
    }
    img{
      display:table;
      margin:0 auto;
      max-height: 500px;
    }
  }
}
