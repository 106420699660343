@import "mixin";

html{
    font-size: 62.5%;
}
body{
    font-weight: 400;
    font-family: YakuHanJP, '游ゴシック Medium', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: .07em;
    position: relative;
    color: #000;
    line-height: 1.6;
    font-size:1.4rem;
    background-color: #fdfdf1;
    min-height: 100vh;
    position:relative;
    padding-bottom: 51px;
    &.home{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0;
        > footer{
            position:relative;
        }
    }
}

body > header{
}

body > footer{
    position:absolute;
    bottom:0;
    left: 0;
    width: 100%;
    height:51px;
    small{
        display:block;
        padding:1.5em;
        text-align: center;
    }
}

*:focus {
  outline: none !important;
}