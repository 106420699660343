#page-profile{
  article{
    max-width:1000px;
    margin: 0 auto;
  }
  h1{
    margin:1.2em 0 1em;
    text-align:center;
    @include max($break-mobile){
      font-size:1.5em;
    }
  }
  .prof-img{
    @include max($break-mobile){
      padding:0;
    }
  }
}
