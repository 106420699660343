@import "mixin";
@import "media";

#pc-head{
  padding: 35px 0;
  position:relative;
  @include max($break-mobile){
    padding: 15px 0 5px;
    .p-n{
      padding:0;
    }
  }
  .main-logo{
    padding:20px;
    position: relative;
    z-index: 1;
    background-color: #fdfdf1;
    @include max($break-mobile){
      padding: 0 30px 20px;
    }
  }
  .logo{
    margin:0 auto;
    display:table;
  }
  .s-menu{
    position:relative;
  }
  .menu-btn{
    display:table;
    margin: 0 auto;
    transform-origin: center;
    transform: scale(.75);
  }
  nav{
    visibility:hidden;
    opacity:0;
    position:absolute;
    width:100%;
    display:table;
    margin: 5px auto 0;
    transition: ease .85s;
    z-index: 2;
    &.show{
      opacity: 1;
      visibility: visible;
      z-index: 2;
      background-color:rgba(255, 255, 242, .9);
    }
    >div{
      display:table;
      width:100%;
      max-width:750px;
      margin:0 auto;
    }
    ul{
      padding-left: 0;
      width:100%;
      margin-left:-15px;
      list-style: none;
      justify-content: space-between;
      @include max(494px){
        justify-content: start;
        flex-wrap: wrap;
        margin-left: 0;
      }
    }
    li{
      margin-left: 15px;
      width: calc(100% / 5);
      @include max(494px){
        width:50%;
        margin-left: 0;
        margin-bottom: 15px;
        &:last-child{
          width:100%;
        }
      }
      img{
        display:table;
        margin:0 auto;
      }
      a{
        color:black;
      }
      span{
        display:block;
        text-align: center;
        margin-top: .5em;
      }
    }
  }
}
